@import "bootstrap-sprockets";
@import "bootstrap";
@import "font-awesome";
@import "slick";
@import "slick-theme";
@import "bootstrap-social";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,600,400italic,700italic,300italic,600italic);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,500italic,600,700,200,100,300,300italic,400italic,600italic);
@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,700,400italic);

body{
  //font-family: 'Open Sans', sans-serif;
  font-family: 'PT Serif', sans-serif;
  font-stretch: expanded;
  background-color: black;
  line-height: 2.2em;
  font-size: 11pt;
  padding-top: 70px;
  padding:10px;
}

.bg-white{
  background-color: white;
}
.bg-black{
  background-color: black;
}
.text-white{
  color: white;
}
.text-black{
  color: black;
}
a{
  color: dimgrey;
}
.navbar{
  margin-left: 10px;
  margin-right: 10px;
  border-top:10px solid black;
  border-bottom: none;
  min-height:100px;
  font-family: 'Raleway',sans-serif;
}

.navbar-nav {

  > li > a {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}


.intro{
}
.intro-wrapper{
  padding: 20px;
}
h1,h2,h3{
  font-family: 'Raleway', sans-serif;
}
.h1-intro{
  font-family: 'Raleway', sans-serif;
  font-size: 35pt;
  font-weight: bold;
  margin-bottom: 44px;
}

.content{
  font-size: 19pt;
}

ul.our-client{
   li{
    background-color: white;
    width: 32.5%;
    min-height: 280px;
    padding: 5% 2% 0 2%;
    vertical-align: middle;
    margin-bottom: 2%;
    color: dimgrey;
    line-height: 1.5em;
    font-family: 'Raleway', sans-serif;
     font-size: 10pt;
    img{
      width: 100%;
      margin-bottom: 5%;
      padding-bottom: 5%;
      border-bottom: 1px solid lightgrey;
    }
  }
}

ul.our-client-mobile{
  li{
    background-color: white;
    width: 49%;
    min-height: 280px;
    padding: 5% 2% 0 2%;
    vertical-align: middle;
    margin-bottom: 2%;
    color: dimgrey;
    line-height: 1.5em;
    font-family: 'Raleway', sans-serif;
    font-size: 10pt;
    img{
      width: 100%;
      margin-bottom: 5%;
      padding-bottom: 5%;
      border-bottom: 1px solid lightgrey;
    }
  }
}